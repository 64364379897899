import { cn } from '@virginexperiencedays/tailwind';
import { HTMLAttributes } from 'react';

export type DividerProps = {
  variant?: 'grey' | 'dots';
  className?: string;
} & HTMLAttributes<HTMLDivElement | HTMLHRElement>;

export const Divider = ({ className, variant = 'grey', ...rest }: DividerProps) => {
  const dotClass = 'w-2 h-2 rounded bg-border-neutral relative float-left my-0 mx-1';
  switch (variant) {
    case 'grey':
      return (
        <hr
          className={cn('border-border-neutral mx-0 my-6 block h-px border-t p-0', className)}
          {...rest}
          data-testid="divider-line"
        />
      );
    default:
      return (
        <div
          className={cn('flex items-center justify-center', className)}
          {...rest}
          data-testid="divider-dots"
        >
          <span className={dotClass}></span>
          <span className={dotClass}></span>
          <span className={dotClass}></span>
        </div>
      );
  }
};
