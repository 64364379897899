import { isEnableServer } from '@virginexperiencedays/feature-flags';

/**
 * NOTE: This was migrated off an enum because as of Typescript <= 5.0.0, only
 * numeric enums can have computed members.
 */
export const indexNames = {
  DEFAULT: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}`,
  PRICE_ASC: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_price_asc`,
  PRICE_DSC: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_price_dsc`,
  NEWEST: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_newest`,
  TOP_RATED: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_rating_dsc`,
  MOST_REVIEWED: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_reviews_dsc`,
  QUERY_SUGGESTIONS: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_query_suggestions`,
};

export const categoriesHierarchyAttributeArray = [
  'facets.categoryHierarchies.lvl0',
  'facets.categoryHierarchies.lvl1',
  'facets.categoryHierarchies.lvl2',
];
export const occasionsHierarchyAttributeArray = [
  'facets.occasionHierarchies.lvl0',
  'facets.occasionHierarchies.lvl1',
  'facets.occasionHierarchies.lvl2',
];
export const featuresHierarchyAttributeArray = [
  'facets.featureHierarchies.lvl0',
  'facets.featureHierarchies.lvl1',
  'facets.featureHierarchies.lvl2',
];

export const locationsHierarchyAttributeArray = [
  'facets.regionHierarchies.lvl0',
  'facets.regionHierarchies.lvl1',
  'facets.regionHierarchies.lvl2',
];

export const nonHierarchyAttributes = ['facets.priceRange', 'facets.reviewRanges'];

export enum refinementList {
  // LOCATION = 'locations.name', // no longer supported
  PRICE_RANGE = 'facets.priceRange',
  RATING = 'facets.reviewRanges',
  CATEGORY = 'facets.categoryHierarchies',
  OCCASION = 'facets.occasionHierarchies',
  FEATURE = 'facets.featureHierarchies',
  // This one is used only for `/location` url, so we can display hierarchical menu of locations for a given region
  LOCATION = 'facets.regionHierarchies',
  GEO_SEARCH = '_geoloc',
}

export enum attributeMapping {
  'facets.categoryHierarchies.lvl0' = 'category',
  'facets.occasionHierarchies.lvl0' = 'occasion',
  'facets.featureHierarchies.lvl0' = 'feature',
  'facets.regionHierarchies.lvl0' = 'location',
  'facets.priceRange' = 'price',
  'facets.reviewRanges' = 'rating',
  'query' = 'query',
}

export enum trackMapping {
  'facets.categoryHierarchies.lvl0' = 'category',
  'facets.occasionHierarchies.lvl0' = 'occasion',
  'facets.featureHierarchies.lvl0' = 'feature',
  'facets.regionHierarchies.lvl0' = 'region',
  'facets.priceRange' = 'price',
  'facets.reviewRanges' = 'rating',
  'query' = 'query',
}

export const ATTRIBUTES_TO_RENDER = Object.values(refinementList);

export const validAttributes = <const>[
  'facets.categoryHierarchies',
  'facets.occasionHierarchies',
  'facets.featureHierarchies',
  'facets.regionHierarchies',
  '_geoloc',
  ...nonHierarchyAttributes,
];

export const facets = <const>[
  'occasion',
  'feature',
  'price',
  'page',
  'location',
  'rating',
  'geoSearch',
  ...(isEnableServer('FF_new_filters') ? ['category'] : []),
  /**
   * Pseudo facets:
   * Technically these aren't facets, but we treat it as is as we move user to PLP once one appears
   */
  'sort',
  'hide',
];

export const MAX_VALUES_PER_FACET = 300;

export const categorySeparator = '/';

export enum categoryTypes {
  OCCASION = 'occasion',
  LOCATION = 'location',
  FEATURE = 'feature',
  CATEGORY = 'category', // master category
  PRICE_RANGE = 'price',
  RATING = 'rating',
  SEARCH = 'search',
}

export enum categoryPrefixes {
  OCCASION = 'o',
  LOCATION = 'location',
  FEATURE = 'f',
  CATEGORY = 'c', // master category prefix
  PRICE_RANGE = 'price', // this has to match the routeParams object, as this is used to translate query param when going from /price to /c
  RATING = 'rating', // this has to match the routeParams object, as this is used to translate query param when going from /rating to /c
  CATEGORY_OVERRIDE = 'cor',
}

export const priceRangeLabels = {
  '0-50': 'Less than £50',
  '50-150': '£50 to £150',
  '150-250': '£150 to £250',
  '250-500': '£250 to £500',
  '500-plus': '£500+',
};

export const EXCLUDED_FACETS = {
  // hide facets that is not relevant for gift cards
  'gift-cards': [
    refinementList.FEATURE,
    refinementList.OCCASION,
    refinementList.GEO_SEARCH,
    refinementList.LOCATION,
  ],
};

export const EXCLUDED_SORT_OPTIONS = {
  // hide sort options that is not relevant for gift cards
  'gift-cards': [indexNames.NEWEST, indexNames.TOP_RATED, indexNames.MOST_REVIEWED],
};
