import {
  occasionsHierarchyAttributeArray,
  featuresHierarchyAttributeArray,
  categoriesHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
  categoryTypes,
  refinementList,
} from '@virginexperiencedays/search/constants';
export function getMainCategoryAttribute(categoryType: categoryTypes): string[] {
  switch (categoryType) {
    case categoryTypes.OCCASION:
      return occasionsHierarchyAttributeArray;
    case categoryTypes.FEATURE:
      return featuresHierarchyAttributeArray;
    case categoryTypes.CATEGORY:
      return categoriesHierarchyAttributeArray;
    case categoryTypes.LOCATION:
      return locationsHierarchyAttributeArray;
    case categoryTypes.PRICE_RANGE:
      return [refinementList.PRICE_RANGE];
    case categoryTypes.RATING:
      return [refinementList.RATING];
    default:
      return categoriesHierarchyAttributeArray;
  }
}
