/**
 * This is minus icon with viewbox 20px x 20px used on add quantity input
 */

import { ComponentPropsWithoutRef } from 'react';

type IconMinusProps = {
  width?: number;
  height?: number;
  fill?: string;
} & ComponentPropsWithoutRef<'svg'>;

export const IconMinus = ({
  width = 20,
  height = 20,
  fill = 'currentColor',
  ...rest
}: IconMinusProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M10.5 9.5H5.75a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5H10.5Z" fill={fill} />
    </svg>
  );
};
