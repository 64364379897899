export const dispatchDynamicContentLoadedEvent = (detail) => {
  if (typeof CustomEvent === 'function') {
    const event = new CustomEvent('DynamicContentLoaded', {
      detail: detail,
    });

    if (document.dispatchEvent) {
      document.dispatchEvent(event);
    }
  }
};

export const emitEvent = (eventName, detail) => {
  if (typeof CustomEvent === 'function') {
    const event = new CustomEvent(eventName, { detail: detail });

    if (document.dispatchEvent) {
      document.dispatchEvent(event);
    }
  }
};
