import { BasketItemFlags, convertBasketItemFlagsToString } from './basketItemFlags';

export const buildQueryString = (id: number, qty: number, flags: BasketItemFlags[]): string => {
  const params = new URLSearchParams();
  params.append('productId', id.toString());
  params.append('qty', qty.toString());

  const flagsString: string = convertBasketItemFlagsToString(flags);
  flagsString && params.append('flags', flagsString);

  return params.toString();
};
