import { cn } from '@virginexperiencedays/tailwind';
import type { HTMLAttributes } from 'react';

export type StyledRichTextProps = HTMLAttributes<HTMLDivElement>;

export const StyledRichText = ({ className, children }: StyledRichTextProps) => {
  return (
    <div
      className={cn(
        styles.base,
        styles.h1,
        styles.h2,
        styles.h3,
        styles.h4,
        styles.ol,
        styles.ul,
        styles.p,
        styles.a,
        className
      )}
    >
      {children}
    </div>
  );
};

const styles = {
  base: 'text-neutral [&_*_+_*]:mt-4',
  h1: '[&_h1]:mt-8 [&_h1]:text-3xl/none [&_h2]:font-bold',
  h2: '[&_h2]:font-inter [&_h2]:text-xl/none [&_h2]:font-bold',
  h3: '[&_h3]:font-inter [&_h3]:text-base/base [&_h3]:font-bold',
  h4: '[&_h4]:font-inter [&_h4]:text-sm/base [&_h4]:font-semibold [&_h4]:uppercase',
  ol: '[&_ol]:list-decimal [&_ol]:list-inside [&_ol]:pl-0',
  ul: '[&_ul]:list-disc [&_ul]:list-inside [&_ul]:pl-0',
  p: '[&_p_+_h2]:mt-6 [&_p_+_h3]:mt-6 [&_p_+_h4]:mt-6',
  a: '[&_a]:underline [&_a]:text-[inherit] hover:[&_a]:text-link',
};
