import { cn } from '@virginexperiencedays/tailwind';

import { Button } from '../../../layout/Button';

type Props = {
  displayPrice: number;
  className?: string;
  onClick?: () => void;
};

export const GiftCardPrice = ({ displayPrice, className, onClick }: Props) => (
  <Button
    className={cn(
      'bg-background-primary hover:bg-background-primary flex aspect-[5/3] w-full items-center justify-center rounded-2xl border-0 focus:border-0',
      'rotate-0 transform transition-transform duration-[0.15s]',
      '[@media(min-width:1024px)]:rotate-0 [@media(min-width:1024px)]:hover:rotate-[-2deg] [@media(min-width:1024px)]:hover:scale-x-[1.1] [@media(min-width:1024px)]:hover:scale-y-[1.1]',
      '[@media(min-width:1024px)]:[&:hover>span]:opacity-100',
      className
    )}
    onClick={onClick}
    aria-label="Add to basket"
    role="link"
  >
    <p className="absolute left-1/2 top-1/2 m-0 -translate-x-1/2 -translate-y-1/2 transform text-2xl font-bold leading-normal text-white">
      £{displayPrice}
    </p>
    <span
      className={cn(
        'leading-base absolute bottom-0 block rounded bg-white px-4 py-2 text-center text-sm font-semibold text-black opacity-100 shadow-md',
        'scale-x-1 scale-y-1 translate-y-4 rotate-0 transform transition-opacity duration-[0.15s]',
        '[@media(min-width:1024px)]:scale-x-1 [@media(min-width:1024px)]:scale-y-1 md:p-4 [@media(min-width:1024px)]:translate-x-0 [@media(min-width:1024px)]:translate-y-[1rem] [@media(min-width:1024px)]:rotate-[2deg] [@media(min-width:1024px)]:opacity-0'
      )}
    >
      Add to basket
    </span>
  </Button>
);
