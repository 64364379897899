export enum BasketItemFlags {
  REPORT_AS_B2B = 'reportAsB2b',
}

export const convertBasketItemFlagsToString = (flags: BasketItemFlags[]): string | null => {
  if (!flags?.length) return null;

  // removing duplicates, [...new Set(flags)] not supported with commonjs
  const uniqueFlags: BasketItemFlags[] = [];
  for (const flag of flags) {
    if (!uniqueFlags.includes(flag)) {
      uniqueFlags.push(flag);
    }
  }

  return uniqueFlags.join(',');
};
