import { ComponentPropsWithoutRef } from 'react';

/**
 *
 * This is 1.5px plus icon with viewbox 20px x 20px used on add quantity input
 */

type IconPlusProps = {
  width?: number;
  height?: number;
  fill?: string;
} & ComponentPropsWithoutRef<'svg'>;

export const IconPlus = ({
  width = 20,
  height = 20,
  fill = 'currentColor',
  ...rest
}: IconPlusProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5.75a.75.75 0 0 0-1.5 0V9.5H5.75a.75.75 0 0 0 0 1.5H9v3.25a.75.75 0 0 0 1.5 0V11h3.75a.75.75 0 0 0 0-1.5H10.5V5.75Z"
        fill={fill}
      />
    </svg>
  );
};
