import { cn } from '@virginexperiencedays/tailwind';

export type Space =
  | 'none'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl';

export type ResponsiveSpace = {
  mobile?: Space;
  tablet?: Space;
  desktop?: Space;
  wide?: Space;
};

export const gapConfig = {
  none: 'gap-0',
  xxs: 'gap-0.5',
  xs: 'gap-1',
  sm: 'gap-2',
  md: 'gap-4',
  lg: 'gap-6',
  xl: 'gap-10',
  '2xl': 'gap-14',
  '3xl': 'gap-[72px]',
  '4xl': 'gap-20',
  '5xl': 'gap-[120px]',
};

const responsiveGapConfig = {
  mobile: gapConfig,
  tablet: {
    none: 'md:gap-0',
    xxs: 'md:gap-0.5',
    xs: 'md:gap-1',
    sm: 'md:gap-2',
    md: 'md:gap-4',
    lg: 'md:gap-6',
    xl: 'md:gap-10',
    '2xl': 'md:gap-14',
    '3xl': 'md:gap-[72px]',
    '4xl': 'md:gap-20',
    '5xl': 'md:gap-[120px]',
  },
  desktop: {
    none: 'lg:gap-0',
    xxs: 'lg:gap-0.5',
    xs: 'lg:gap-1',
    sm: 'lg:gap-2',
    md: 'lg:gap-4',
    lg: 'lg:gap-6',
    xl: 'lg:gap-10',
    '2xl': 'lg:gap-14',
    '3xl': 'lg:gap-[72px]',
    '4xl': 'lg:gap-20',
    '5xl': 'lg:gap-[120px]',
  },

  wide: {
    none: 'xl:gap-0',
    xxs: 'xl:gap-0.5',
    xs: 'xl:gap-1',
    sm: 'xl:gap-2',
    md: 'xl:gap-4',
    lg: 'xl:gap-6',
    xl: 'xl:gap-10',
    '2xl': 'xl:gap-14',
    '3xl': 'xl:gap-[72px]',
    '4xl': 'xl:gap-20',
    '5xl': 'xl:gap-[120px]',
  },
};

export const gapYConfig = {
  none: 'gap-y-0',
  xxs: 'gap-y-0.5',
  xs: 'gap-y-1',
  sm: 'gap-y-2',
  md: 'gap-y-4',
  lg: 'gap-y-6',
  xl: 'gap-y-10',
  '2xl': 'gap-y-14',
  '3xl': 'gap-y-[72px]',
  '4xl': 'gap-y-20',
  '5xl': 'gap-y-[120px]',
};

const responsiveGapYConfig = {
  mobile: gapYConfig,
  tablet: {
    none: 'md:gap-y-0',
    xxs: 'md:gap-y-0.5',
    xs: 'md:gap-y-1',
    sm: 'md:gap-y-2',
    md: 'md:gap-y-4',
    lg: 'md:gap-y-6',
    xl: 'md:gap-y-10',
    '2xl': 'md:gap-y-14',
    '3xl': 'md:gap-y-[72px]',
    '4xl': 'md:gap-y-20',
    '5xl': 'md:gap-y-[120px]',
  },
  desktop: {
    none: 'lg:gap-y-0',
    xxs: 'lg:gap-y-0.5',
    xs: 'lg:gap-y-1',
    sm: 'lg:gap-y-2',
    md: 'lg:gap-y-4',
    lg: 'lg:gap-y-6',
    xl: 'lg:gap-y-10',
    '2xl': 'lg:gap-y-14',
    '3xl': 'lg:gap-y-[72px]',
    '4xl': 'lg:gap-y-20',
    '5xl': 'lg:gap-y-[120px]',
  },

  wide: {
    none: 'xl:gap-y-0',
    xxs: 'xl:gap-y-0.5',
    xs: 'xl:gap-y-1',
    sm: 'xl:gap-y-2',
    md: 'xl:gap-y-4',
    lg: 'xl:gap-y-6',
    xl: 'xl:gap-y-10',
    '2xl': 'xl:gap-y-14',
    '3xl': 'xl:gap-y-[72px]',
    '4xl': 'xl:gap-y-20',
    '5xl': 'xl:gap-y-[120px]',
  },
};

// THIS SHOULD BE EXPANDED ONLY PER USAGE
// keep in mind that adding more values to this function will include them in final css bundle
// as when this function is compiled it uses all tailwind utilties even if  they are not used in the final styles
export function mapGap(space: Space | ResponsiveSpace): string {
  if (typeof space === 'string') {
    return gapConfig[space];
  }

  const { mobile = 'sm', tablet, desktop, wide } = space;

  const mobileStyle = responsiveGapConfig.mobile[mobile];
  const tabletStyle = tablet ? responsiveGapConfig.tablet[tablet] : null;
  const desktopStyle = desktop ? responsiveGapConfig.desktop[desktop] : null;
  const wideStyle = wide ? responsiveGapConfig.wide[wide] : null;

  return cn(mobileStyle, tabletStyle, desktopStyle, wideStyle);
}
export function mapGapY(space: Space | ResponsiveSpace): string {
  if (typeof space === 'string') {
    return gapYConfig[space];
  }

  const { mobile = 'sm', tablet, desktop, wide } = space;

  const mobileStyle = responsiveGapYConfig.mobile[mobile];
  const tabletStyle = tablet ? responsiveGapYConfig.tablet[tablet] : null;
  const desktopStyle = desktop ? responsiveGapYConfig.desktop[desktop] : null;
  const wideStyle = wide ? responsiveGapYConfig.wide[wide] : null;

  return cn(mobileStyle, tabletStyle, desktopStyle, wideStyle);
}
