import { useState } from 'react';

import { addToBasket } from '@virginexperiencedays/basket';
import { cn } from '@virginexperiencedays/tailwind';

import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { GiftCardPrice } from '@virginexperiencedays/components-v2/src/pages/gift-card/GiftCardPrice';
import { DisplayHeading } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';
import { BodyText } from '@virginexperiencedays/components-v2/src/typography/BodyText';

import type { PrismicContext } from '../../types/context';

type GiftCardPriceSelectorProps = {
  slice: {
    primary: {
      title: string;
      description: string;
      introTitle?: string;
      card_count: '4' | '6' | '8';
    };
    items: {
      id: number;
      price: { displayPrice: number };
    }[];
    slice_type: string;
  };
  context: PrismicContext;
  className?: string;
};

const GiftCardPriceSelector = ({ slice, context, className }: GiftCardPriceSelectorProps) => {
  const {
    primary: { title, description, introTitle = 'Buy a Gift Card', card_count },
    items,
    slice_type,
  } = slice;
  const cardCount = card_count ?? '4';
  const { isFaceted, trackSlice, pageType, lastSlug } = context;
  const [error, setError] = useState(false);

  if (isFaceted || !Array.isArray(items)) {
    return null;
  }

  const sortedItems = items
    // filter out null-ish and zero prices
    .filter((item) => item?.price?.displayPrice)
    // sort by price, ascending
    .sort((a, b) => a.price.displayPrice - b.price.displayPrice)
    .slice(0, Number(cardCount));

  if (!sortedItems) return null;

  const addToBasketWithQty = async ({ productId, qty }: { productId: number; qty: number }) => {
    // tracking
    const product = sortedItems.find((item) => item?.id === productId);
    trackSlice?.({
      slug: lastSlug,
      label: String(product?.price?.displayPrice ?? ''),
      sliceType: slice_type,
      pageType,
    });

    try {
      const basket = await addToBasket(productId, qty, null, null);

      if (!basket?.Items?.length) throw new Error('failed to add item(s) to basket');

      window.location.href = '/basket';
    } catch (e) {
      console.error('Error thrown in Gift Card Price Selector while adding to basket', e);
      setError(true);
    }
  };

  // only card_count values for now are 4, 6, and 8, and the 8 case is handled with a 4-col grid
  const cardPerCol = cardCount === '8' ? '4' : cardCount;

  return (
    <Container className={className}>
      <div className="flex flex-col gap-2 text-center">
        <BodyText className="text-brand font-bold">{introTitle}</BodyText>
        <DisplayHeading size="2" leading="none" className="text-neutral-strong m-0 tracking-tight">
          {title}
        </DisplayHeading>
        <BodyText className="text-black">{description}</BodyText>
      </div>
      <div
        className={cn(
          'mt-12 grid grid-cols-2 gap-x-6 gap-y-14',
          styles.wrapperByCardCount?.[cardPerCol] || styles.wrapperByCardCount['4'],
          cardPerCol === '6'
            ? '[@media(min-width:1024px)]:mx-0'
            : '[@media(min-width:1024px)]:mx-20',
          cardPerCol === '6' ? 'xl:!mx-[72px]' : 'xl:!mx-[120px]' // had to use important here as previous arbitrary somehow keeps on taking precedence
        )}
      >
        {sortedItems.map((item) => (
          <GiftCardPrice
            displayPrice={item?.price?.displayPrice}
            key={item.id}
            onClick={() => addToBasketWithQty({ productId: item.id, qty: 1 })}
          />
        ))}
      </div>
      {error && (
        <BodyText className="text-tones-critical-500 mt-4 text-center">
          Something went wrong, please try again
        </BodyText>
      )}
    </Container>
  );
};

export default GiftCardPriceSelector;

const styles = {
  wrapperByCardCount: {
    '1': 'md:grid-cols-1',
    '2': 'md:grid-cols-2',
    '3': 'md:grid-cols-3',
    '4': 'md:grid-cols-4',
    '5': 'md:grid-cols-5',
    '6': 'md:grid-cols-6',
    '7': 'md:grid-cols-7',
    '8': 'md:grid-cols-8',
  },
};
