import { DisplayHeading, DisplayHeadingProps } from '../typography/DisplayHeading';

type Props = {
  /**
   * Title
   */
  title?: string;
  /**
   * Title's element to render as
   */
  titleAs?: DisplayHeadingProps['as'];
  /**
   * Subtitle
   */
  subtitle?: string;
  /**
   * Subtitle's element to render as
   */
  subtitleAs?: DisplayHeadingProps['as'];
};

export const Title = ({ title, titleAs, subtitle, subtitleAs }: Props) => {
  if (!title && !subtitle) return null;

  return (
    <div data-testid="title-comp" className="flex flex-col gap-1 text-center lg:gap-2">
      {!!title && (
        <DisplayHeading
          as={titleAs || 'h2'}
          data-testid="title-comp-title"
          size="5"
          leading="snug"
          className="text-brand overflow-wrap-anywhere lg:text-base"
        >
          {title}
        </DisplayHeading>
      )}
      {!!subtitle && (
        <DisplayHeading
          as={subtitleAs || 'h3'}
          data-testid="title-comp-subtitle"
          size="2"
          leading="normal"
          className="text-neutral-strong overflow-wrap-anywhere"
        >
          {subtitle}
        </DisplayHeading>
      )}
    </div>
  );
};
