import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

export type StackProps = {
  children: ReactNode;
  id?: string;
  className?: string;
  dataTestId?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Stack = ({ id, children, className, dataTestId = 'stack', ...rest }: StackProps) => {
  return (
    <div
      className={cn(
        'block [&>*_+_*]:mt-10 [&>.spacer-component]:mt-0 [&>.spacer-component_+_*]:mt-0',
        '[.cms-richtext]:p:first:mt-0 [.cms-richtext]:p:last:mt-0',
        className
      )}
      id={id}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </div>
  );
};
