/**
 * Identifier for the type of alert returned from the API
 */
export enum AlertType {
  GIFT_CARD = 3,
}

/**
 * ApiItem represents the structure of each item in the basket returned from the API.
 */
export type ApiItem = {
  Id?: string;
  ProductId?: number;
  Name?: string;
  Qty?: number;
  RRP?: number;
  UnitPrice?: number;
  UnitPriceInclDiscount?: number;
  ProductImageThumbURL?: string;
  TrimmedName?: string;
  SKU?: string;
};

/**
 * BasketItem values are derived from the ApiItem type.
 */
export type BasketItem = {
  id: string;
  title: string;
  qty: number;
  rrp: number;
  displayPrice: number;
  discountedPrice?: number;
  image: {
    src: string;
    alt: string;
  };
  sku: string;
};

/**
 *
 */
export type BasketDataApiResponse = {
  Items: ApiItem[];
  BasketItemTotal: number;
  PromoCodeDiscountAmount: number;
  DiscountSaving: number;
};

/**
 * StatusApiResponse represents the structure of the status response from the API.
 */
export type StatusApiResponse = {
  Success: boolean;
  Alert: {
    AlertType: AlertType;
    AlertMessage: string;
    Indefinite: boolean;
  } | null;
};

/**
 *
 */
export type BasketApiResponse = {
  Basket: BasketDataApiResponse;
  Status: StatusApiResponse;
};
