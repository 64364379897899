import { cn } from '@virginexperiencedays/tailwind';

import { IconGiftCard } from '../../../icons/IconGiftCard';
import { Container } from '../../../layout/Container';
import { DisplayHeading } from '../../../typography/DisplayHeading';
import { BodyText } from '../../../typography/BodyText';

type UspItemProps = {
  content: string;
  title: string;
};

type Props = {
  items: UspItemProps[];
  subtext: string;
  title: string;
  className?: string;
  withIcon?: boolean;
};

export const GiftCardUsp = ({ items, subtext, title, className, withIcon }: Props) => {
  // Take max of 4 that have title atleast
  const filteredItems = items?.filter((i) => i.title).slice(0, 4);

  if (!filteredItems?.length || !title) return null;

  return (
    <Container className={cn('grid gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-10', className)}>
      <div className="pr-6 md:col-span-2 lg:col-span-1 lg:row-span-2">
        {withIcon && <IconGiftCard width={64} height={64} className="mb-4" />}
        <DisplayHeading
          data-testid="giftcard-usp-title"
          size="1"
          className="text-neutral-strong break-words tracking-tight md:text-5xl md:leading-tight"
        >
          {title}
        </DisplayHeading>
        {!!subtext && (
          <BodyText data-testid="giftcard-usp-subtext" className="mt-2">
            {subtext}
          </BodyText>
        )}
      </div>

      {filteredItems.map((item: UspItemProps) => (
        <UspItem key={item.title} {...item} />
      ))}
    </Container>
  );
};

// No guards here, parent filters results first to ensure atleast title exists.
export const UspItem = ({ title, content }: UspItemProps) => {
  return (
    <div className="bg-background-primary-faded-lighter rounded-xl px-6 py-10">
      <DisplayHeading size="3" className="text-neutral-strong">
        {title}
      </DisplayHeading>
      {!!content && <BodyText className="mt-2">{content}</BodyText>}
    </div>
  );
};
