import { useState, useEffect, useRef, HTMLAttributes } from 'react';

import { useWindowWidth } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

import { GIFT_CARD_SLIDER } from '../../../constants/images';

import { Container } from '../../../layout/Container';
import { Image } from '../../../layout/Image';

type Props = {
  className?: string;
};

export const GiftCardSlider = ({ className }: Props) => {
  const [scrollY, setScrollY] = useState(0);
  const giftCardSliderRef = useRef<HTMLDivElement>();
  const { width } = useWindowWidth();

  const isMobile = width < 768;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sliderOffSetTop = !isNaN(giftCardSliderRef?.current?.offsetTop)
    ? giftCardSliderRef?.current?.offsetTop
    : 0;
  // visually tweaked so that it starts sliding an offset distance above the slice
  // but offset should be 0 if the slice is at the top of the page, so it doesn't open immediately
  const elementOffset = sliderOffSetTop >= 220 ? sliderOffSetTop - 350 : 0;
  // Calculate the transformation based on scrollY
  const scrollStartValue = elementOffset <= scrollY ? scrollY - Math.max(0, elementOffset) : 0;
  const maxTransform = isMobile ? 170 : 240;

  const scaleValue = scrollStartValue >= maxTransform ? maxTransform : scrollStartValue;

  return (
    <Container className={cn('overflow-hidden', className)}>
      <div
        ref={giftCardSliderRef}
        className={cn(
          'image:[backface-visibility:hidden] grid items-center justify-center p-4',
          'translate-z-0 translate-x-0 translate-y-0 -rotate-12 scale-75 transform will-change-transform',
          '[@media(min-width:1024px)]:rotate-0 [@media(min-width:1024px)]:scale-100'
        )}
      >
        <GiftCardContainer
          data-testid="giftcard-tray"
          style={{ transform: `translate3d(${scaleValue}px, 0, 0)` }}
        >
          <Image
            className={styles.giftCardImage}
            alt="Gift Card Tray"
            src={GIFT_CARD_SLIDER.tray}
            fill
          />
        </GiftCardContainer>
        <GiftCardContainer
          data-testid="giftcard-wallet"
          style={{ transform: `translate3d(-${scaleValue}px, 0, 0)` }}
        >
          <Image
            className={styles.giftCardImage}
            alt="Gift Card Wallet"
            src={GIFT_CARD_SLIDER.wallet}
            fill
          />
        </GiftCardContainer>
      </div>
    </Container>
  );
};

const GiftCardContainer = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={cn(styles.giftCardContainer, className)}>
    {children}
  </div>
);

const styles = {
  giftCardContainer:
    'col-span-full row-span-full transform will-change-transform overflow-hidden border border-transparent [&_img]:[image-rendering:pixelated] w-[390px] h-[293px] md:w-[600px] md:h-[452px]',
  giftCardImage: 'h-full',
};
