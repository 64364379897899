import { GiftCardUsp as Component } from '@virginexperiencedays/components-v2/src/pages/gift-card/GiftCardUsp';

import type { PrismicContext } from '../../types/context';

type GiftCardUspProps = {
  slice: {
    primary: {
      title: string;
      subtext: string;
      with_icon: boolean;
    };
    items: {
      title: string;
      content: string;
    }[];
  };
  context: PrismicContext;
};

const GiftCardUsp = ({ slice, context }: GiftCardUspProps) => {
  const { isFaceted } = context;
  const { title, subtext, with_icon } = slice?.primary ?? {};

  if (isFaceted || !title) return null;

  return <Component title={title} subtext={subtext} withIcon={with_icon} items={slice.items} />;
};
export default GiftCardUsp;
