// Components
import { Title as Component } from '@virginexperiencedays/components-v2/src/Title';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
// Types
import type { PrismicContext } from '../../types/context';

type TitleProps = {
  slice: {
    primary: {
      title: string;
      title_size: '1' | '2' | '3' | '4' | '5' | '6';
      subtitle: string;
      subtitle_size: '1' | '2' | '3' | '4' | '5' | '6';
    };
  };
  context: PrismicContext;
};

const Title = ({ slice, context }: TitleProps) => {
  const {
    primary: { title, title_size, subtitle, subtitle_size },
  } = slice;
  const { isFaceted } = context;

  if (isFaceted) return null;

  return (
    <Container>
      <Component
        title={title}
        titleAs={`h${title_size ?? '2'}` as any}
        subtitle={subtitle}
        subtitleAs={`h${subtitle_size ?? '3'}` as any}
      />
    </Container>
  );
};

export default Title;
