import { refinementList } from '@virginexperiencedays/search/constants';
import type { AlgoliaAttribute } from './stateToRoute';

// use it to target attribute based on its attribute type when using `uiState` or `renderState`  properties from `useInstantSearch()` hook
export function getAttributeType(attribute: AlgoliaAttribute) {
  if (attribute === refinementList.RATING) {
    return 'menu';
  }

  return 'refinementList';
}
