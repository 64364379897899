import React, { HTMLAttributes } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

export type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  // Define whether we want to include padding on mobile view or not
  mobilePadding?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Container = ({
  className,
  children,
  dataTestId = 'container',
  mobilePadding = true,
  ...rest
}: ContainerProps) => {
  return (
    <div
      className={cn('mx-auto max-w-[1440px] md:px-10 xl:px-20', mobilePadding && 'px-4', className)}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </div>
  );
};
