import { PrismicContext } from '../../types/context';
type SliceProps = {
  slice: {
    primary: {
      content: string;
    };
  };
  context: PrismicContext;
};

const HtmlEmbed = ({ slice, context }: SliceProps) => {
  const { hide, isFaceted } = context;

  if (isFaceted || hide) {
    return null;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: slice.primary.content,
      }}
    />
  );
};

export default HtmlEmbed;
